import { retryOnException } from '@atlaskit/frontend-utilities/retry-operation';
import { type UFOExperience } from '@atlassian/ufo';

import { type ActivityFilters } from '../../../common/types/options/activity-filters';
import { type ActivityQuery } from '../../../common/types/options/activity-queries';
import {
	type ActivitiesData,
	type ActivityEventType,
	type ActivityItem,
	type ActivityObjectType,
	type Container,
	type Contributor,
	WORKED_EVENT,
} from '../../../common/types/query';
import { packageName, packageVersion } from '../../../common/utils/analytics/package-context';
import { fetchActivitiesWithExperience } from '../../../common/utils/analytics/utils';
import { postResource } from '../../../utils/fetch';
import { GraphQLFragmentError } from '../../../utils/fetch/errors/GraphQLFragmentError';
import { GraphQLJSError } from '../../../utils/fetch/errors/GraphQLJSError';
import { GraphQLNoResultsError } from '../../../utils/fetch/errors/GraphQLNoResults';
import { hydratePolymorphicData } from '../../../utils/hydrator';
import { type Hydrators } from '../../../utils/hydrator/types';
import {
	hydrateConfluenceIcon,
	hydratedValueToString,
	hydrateIcon,
} from '../../../utils/hydrator/utils';
import HydratorConfigClient, { type FlightDeckConfig } from '../../hydrator-config-client';
import { ActivityClient } from '../base';
import { type ClientHandlers } from '../index';

import graphQLErrorHandler from './error-handling';
import { generateGraphQLRequest, type GraphQLRequest } from './query';
import { type GraphQLError, type GraphQLResponse } from './types';
import { type ActivityItemEdge } from './types/generated';
import {
	activityRoot,
	filterInvalidFragments,
	filtersToMetadata,
	isEmptyActivitiesWithErrorsResponse,
	parseRootContainerARI,
	rollupActivitiesData,
} from './utils';

const CONFLUENCE_COMMENT_TYPENAMES = [
	'ConfluenceComment',
	'ConfluenceFooterComment',
	'ConfluenceInlineComment',
];

const IGNORED_HTTP_ERRORS = new Map<number, RegExp[]>([
	// ignore all 401 errors
	[401, [/.*/]],
	[403, [/Invalid CORS request/]],
]);
const IGNORED_ERROR_MESSAGES = ['The operation was aborted.'];

const DEFAULT_TAGS = {
	source: packageName,
	sourcePackageVersion: packageVersion,
};
export class ActivityClientV3 extends ActivityClient {
	private readonly hydratorConfigClient;
	private readonly clientHandlers?: ClientHandlers;

	constructor(
		product?: string,
		baseUrl?: string,
		logger?: ClientHandlers,
		configFetch?: () => Promise<FlightDeckConfig>,
	) {
		super(
			'v3',
			product,
			{
				'X-ExperimentalApi': 'ActivityV3Api',
			},
			baseUrl,
		);

		this.clientHandlers = logger;
		this.hydratorConfigClient = new HydratorConfigClient(
			{
				captureException: this.captureException,
				captureWarning: this.captureWarning,
			},
			product,
			configFetch,
		);
	}

	setProduct = (product: string) => {
		this.product = product;
	};

	captureException = (error: Error, tags?: Record<string, string>) => {
		if (this.clientHandlers) {
			this.clientHandlers.captureException(error, {
				...DEFAULT_TAGS,
				...tags,
			});
		}
	};

	captureWarning = (message: string, tags?: Record<string, string>) => {
		if (this.clientHandlers) {
			this.clientHandlers.captureWarning(message, {
				...DEFAULT_TAGS,
				...tags,
			});
		}
	};

	private fetchHydratorConfig = async () => await this.hydratorConfigClient.fetchConfig();

	private handleGraphQLErrors = (errors: GraphQLError[] | undefined): string[] => {
		const invalidFragments: string[] = [];
		if (Array.isArray(errors)) {
			for (const err of errors) {
				this.captureException(new GraphQLJSError(err));

				const invalidFragment = graphQLErrorHandler(err);
				if (invalidFragment) {
					invalidFragments.push(invalidFragment);
				}
			}

			return invalidFragments;
		}

		return [];
	};

	private fetchActivitiesV3 = async (
		experience: UFOExperience,
		retryMS: number[],
		filters: ActivityFilters,
		activityQueries: ActivityQuery[],
		deduplicateActivities: boolean = true,
	): Promise<ActivitiesData> => {
		let { fragments, hydrators } = await this.fetchHydratorConfig();
		experience.mark('GeneratedQueryWithFilters_start');

		let response: GraphQLResponse | undefined;
		try {
			response = await retryOnException(
				async () => {
					const request = generateGraphQLRequest(this.product, filters, activityQueries, fragments);
					experience.mark('GeneratedQueryWithFilters_end');
					experience.mark('FetchedActivities_start');
					experience.addMetadata({
						filters: filtersToMetadata(filters),
					});

					const res = await postResource<GraphQLResponse, GraphQLRequest>(
						this.baseUrl,
						request,
						retryMS,
						{
							...this.headers,
						},
					);

					experience.mark('FetchedActivities_end');

					const invalidFragments: string[] = this.handleGraphQLErrors(res?.errors);

					if (invalidFragments.length > 0) {
						fragments = filterInvalidFragments(invalidFragments, fragments);

						throw new GraphQLFragmentError(`Missing fragments: [${invalidFragments.toString()}]`);
					}

					return res;
				},
				{
					intervalsMS: [0, 0, 0],
					retryOn: (err) =>
						[GraphQLFragmentError].some((retriableErr) => err instanceof retriableErr),
					captureException: this.captureException,
				},
			);
		} catch (error: any) {
			if (
				!error ||
				(!IGNORED_HTTP_ERRORS.get(error.statusCode)?.some((regExp) => regExp.test(error.message)) &&
					!IGNORED_ERROR_MESSAGES.includes(error.message))
			) {
				throw error;
			}
		}

		if (isEmptyActivitiesWithErrorsResponse(response)) {
			throw new GraphQLNoResultsError(response.errors);
		}

		if (!response || !response.data?.activity) {
			return { workedOn: [], viewed: [], all: [] };
		}

		experience.mark('TransformedActivityItems_start');

		const workedOn = transformActivityItem(
			'workedOn',
			activityRoot(response.data.activity)?.workedOn?.edges || [],
			hydrators,
			this.captureException,
		);

		// We don't need the activity root function here as viewed only exists on myActivity
		const viewed = transformActivityItem(
			'viewed',
			response.data.activity?.myActivity?.viewed?.edges || [],
			hydrators,
			this.captureException,
		);

		const all = transformActivityItem(
			'all',
			activityRoot(response.data.activity)?.all?.edges || [],
			hydrators,
			this.captureException,
		);
		experience.mark('TransformedActivityItems_end');

		const activities: ActivitiesData = { viewed, workedOn, all };
		return deduplicateActivities ? rollupActivitiesData(activities) : activities;
	};

	fetchActivities = async (
		retryMS: number[],
		filters: ActivityFilters,
		activityQueries: ActivityQuery[],
		deduplicateActivities: boolean = true,
	): Promise<ActivitiesData> => {
		return fetchActivitiesWithExperience(
			(experience) =>
				this.fetchActivitiesV3(
					experience,
					retryMS,
					filters,
					activityQueries,
					deduplicateActivities,
				),
			'v3',
		);
	};
}

function transformActivityItem(
	query: ActivityQuery,
	activities: ActivityItemEdge[],
	hydrators: Hydrators,
	captureException?: (error: Error, tags?: Record<string, string>) => void,
): ActivityItem[] {
	return activities.reduce<ActivityItem[]>((acc, item) => {
		if (item && item.node && item.node.object && item.node.event) {
			const { object, event } = item.node;
			if (!object.product || !object.type) {
				return acc;
			}

			const containers: Container[] = [];

			let c = object.contributors || [];
			const contributors = c.reduce<Contributor[]>((contributors, contributor) => {
				if (contributor.profile) {
					const { accountId, picture } = contributor.profile;
					contributors.push({
						accountId,
						name: contributor.profile.name,
						picture,
					});
				}
				return contributors;
			}, [] as Contributor[]);

			const hydratedData = hydratePolymorphicData(hydrators, object.data, captureException);

			if (hydratedData) {
				if (hydratedData.key) {
					containers.push({
						name: hydratedValueToString(hydratedData.key, ' ')!,
					});
				}
				if (hydratedData.containers) {
					containers.push({
						name: hydratedValueToString(hydratedData.containers, ' ')!,
					});
				}
				const actor = event.actor
					? {
							name: event.actor.name,
							picture: event.actor.picture,
							accountId: event.actor.accountId,
						}
					: undefined;

				// The V3 API returns "Created" for a new comment, we want this to display as "Commented"
				const eventType =
					object.type === 'comment' && event.eventType === WORKED_EVENT.CREATED
						? WORKED_EVENT.COMMENTED
						: (event.eventType as ActivityEventType);

				acc.push({
					actor,
					query,
					containers,
					contributors,
					eventType,
					object: {
						rootContainerIdentifier: parseRootContainerARI(object.rootContainerId),
						icon: CONFLUENCE_COMMENT_TYPENAMES.includes(object.data.__typename)
							? hydrateConfluenceIcon(
									object.data?.container?.__typename,
									hydratedData.icon,
									hydratedData.staticFallbacks,
								)
							: hydrateIcon(hydratedData.icon, hydratedData.staticFallbacks),
						id: object.id,
						deduplicateId: hydratedValueToString(hydratedData.deduplicateId) || object.id,
						localResourceId: hydratedValueToString(hydratedData.localResourceId),
						name: hydratedValueToString(hydratedData.name, ' '),
						product: object.product,
						subProduct: object.subProduct,
						type: object.type as ActivityObjectType,
						url: hydratedValueToString(hydratedData.url)!,
					},
					timestamp: event.timestamp,
				});
			}
		}

		return acc;
	}, []);
}
