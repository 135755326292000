import get from 'lodash/get';

import HydratorError from './error';
import {
	type HydratedData,
	type HydrationContext,
	type Hydrator,
	type HydratorPaths,
	type Hydrators,
} from './types';

export const hydratePolymorphicData = (
	hydrators: Hydrators,
	data?: HydrationContext | null,
	captureException?: (error: Error, tags?: Record<string, string>) => void,
): HydratedData | null => {
	if (data) {
		if ('__typename' in data) {
			const hydrator = hydrators[data.__typename];
			if (hydrator) {
				try {
					return hydrateData(data, hydrator);
				} catch (error) {
					captureException &&
						captureException(
							new HydratorError({
								error,
								message: `Hydrator failed for [${data.__typename}].`,
							}),
						);
				}
			} else {
				captureException &&
					captureException(
						new HydratorError({
							message: `Missing hydrator for [${data.__typename}].`,
						}),
					);
			}
		} else {
			captureException &&
				captureException(
					new HydratorError({
						message: `Missing [__typename] field from data.`,
					}),
				);
		}
	}

	return null;
};

function getOrSearch(object: any, path: string) {
	if (!object) {
		return object;
	}

	if (path.includes(':')) {
		const [searchField, searchValue] = path.split(':');

		return findNodeByField(
			object,
			searchField.trim(),
			searchValue.trim().replace(/"/g, ''), // Strip any preceding whitesepace and quotes
		);
	}
	return get(object, path);
}

function resolvePath(object: any, path: string) {
	// Split the path on each bracket
	const splitPath = path.split(/\(|\)./);

	// Traverse the split path as a tree, resolving as we go
	let current = object;
	for (const split of splitPath) {
		current = getOrSearch(current, split);
	}

	return current;
}

function findNodeByField(edges: any[], fieldId: string, searchValue: string) {
	return edges.find((edge) => get(edge, fieldId) === searchValue);
}

export function getValues(object: any, paths: string[]) {
	return paths.map<string>((path) => resolvePath(object, path));
}

type StaticFallbacks = { icon?: string };
type HydratorValues = string | string[] | StaticFallbacks;

const hydrateData = (data: HydrationContext, hydrator: Hydrator): HydratedData => {
	const { hydratorPaths, staticFallbacks } = hydrator;

	return {
		staticFallbacks,
		...(
			Object.entries(hydratorPaths) as Array<[keyof HydratorPaths, HydratorValues]>
		).reduce<HydratedData>((acc, [key, dataValue]) => {
			const item = getValues(data, dataValue as string[]);
			if (item) {
				acc[key] = item;
			} else {
				throw new Error(`Bad hydrator path: ${key}`);
			}

			return acc;
		}, {} as HydratedData),
	};
};
